import React from 'react';

class Navbar extends React.Component {
  render() {
    return (
      <nav className='navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark'>
        <div className='container'>
          <a className='navbar-brand logo text-uppercase' href='/'>
            <img src='images/altus-logo.png' alt='Altus Techs Logo' />
          </a>
          <button
            className='navbar-toggler'
            type='button'
            data-toggle='collapse'
            data-target='#navbarCollapse'
            aria-controls='navbarCollapse'
            aria-expanded='false'
            aria-label='Toggle navigation'>
            <i className='mdi mdi-menu' />
          </button>
          <div className='collapse navbar-collapse' id='navbarCollapse'>
            <ul className='navbar-nav navbar-center' id='mySidenav'>
              <li className='nav-item active'>
                <a href='#home' className='nav-link' data-toggle='collapse' data-target='.navbar-collapse.show'>
                  Home
                </a>
              </li>
              <li className='nav-item'>
                <a
                  href='#app-development'
                  className='nav-link'
                  data-toggle='collapse'
                  data-target='.navbar-collapse.show'>
                  Application
                </a>
              </li>
              <li className='nav-item'>
                <a
                  href='#mobile-development'
                  className='nav-link'
                  data-toggle='collapse'
                  data-target='.navbar-collapse.show'>
                  Mobile
                </a>
              </li>
              <li className='nav-item'>
                <a
                  href='#consulting-service'
                  className='nav-link'
                  data-toggle='collapse'
                  data-target='.navbar-collapse.show'>
                  Consulting
                </a>
              </li>
              <li className='nav-item'>
                <a
                  href='#test-automation'
                  className='nav-link'
                  data-toggle='collapse'
                  data-target='.navbar-collapse.show'>
                  Testing
                </a>
              </li>
              <li className='nav-item'>
                <a href='#web-design' className='nav-link' data-toggle='collapse' data-target='.navbar-collapse.show'>
                  Design
                </a>
              </li>
              {/*<li className='nav-item'>
                <a href='#boot-camps' className='nav-link' data-toggle='collapse' data-target='.navbar-collapse.show'>
                  Boot Camps
                </a>
              </li>
              <li className='nav-item'>
                <a href='#training' className='nav-link' data-toggle='collapse' data-target='.navbar-collapse.show'>
                  Boot Camps
                </a>
							</li>
              <li className='nav-item'>
                <a href='#team' className='nav-link' data-toggle='collapse' data-target='.navbar-collapse.show'>
                  Team
                </a>
              </li>*/}
              <li className='nav-item'>
                <a href='#contact' className='nav-link' data-toggle='collapse' data-target='.navbar-collapse.show'>
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
