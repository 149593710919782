import React from "react";

//import { Link } from 'react-router-dom';

class WebDesign extends React.Component {
  render() {
    return (
      <section className="section bg-gray" id="web-design">
        <div className="container">
          <div className="row vertical-content">
            <div className="col-lg-5">
              <div className="features-box">
                <h3>Web Design</h3>
                <p className="text-muted web-desc">
                  Our proficiency lies with website design,
                  website graphic design, corporate identity and logo design,
                  brochure design, banner design, E-catalog and e-publishing
                  services and more. Our web design service can
                  help businesses establish and maintain a strong online
                  presence, which can be important for attracting and engaging
                  customers and supporting business goals.
                </p>
                {/* <Link to='' className='btn btn-custom margin-t-30 waves-effect waves-light'>
                  Learn More <i className='mdi mdi-arrow-right' />
                </Link> */}
              </div>
            </div>
            <div className="col-lg-7">
              <div className="features-img features-right text-right">
                <img
                  src="images/services/web-design.png"
                  alt="macbook"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default WebDesign;
