import "url-search-params-polyfill";

import Aux from "../hoc/Aux_";
import Footer from "../components/Footer";
import React from "react";
import SocialMedia from "../components/SocialMedia";

class Mission extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  
  render() {
    const bkg1 = {
      backgroundImage: "url(images/wave-shape/wave1.png)",
    };
    const bkg2 = {
      backgroundImage: "url(images/wave-shape/wave2.png)",
    };
    const bkg3 = {
      backgroundImage: "url(images/wave-shape/wave3.png)",
    };

    return (
      <Aux>
        <div className="account-home-btn d-sm-block">
          <a href="/" className="text-white">
            <i className="mdi mdi-home h1" />
          </a>
        </div>
        <section
          className="section bg-home home-half"
          id="home"
          data-image-src="images/bg-home.jpg"
        >
          <div className="bg-overlay" />
          <div className="display-table">
            <div className="display-table-cell">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 offset-lg-2 text-white text-center">
                    <h1 className="home-title">Our Mission</h1>
                    <p className="padding-t-15 home-desc">
                      Altus Techs is a domestic IT and engineering solution
                      provider whose business focuses on enterprise application
                      development and consulting. We offer an optimal blend of
                      business processes and IT solutions in varied domains to
                      enable our customers to meet their corporate goals.
                    </p>
                    <p className="play-shadow margin-t-30 margin-l-r-auto"> </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="wave-effect wave-anim">
            <div className="waves-shape shape-one">
              <div className="wave wave-one" style={bkg1} />
            </div>
            <div className="waves-shape shape-two">
              <div className="wave wave-two" style={bkg2} />
            </div>
            <div className="waves-shape shape-three">
              <div className="wave wave-three" style={bkg3} />
            </div>
          </div>
        </section>
        <div className="display-table">
          <div className="display-table-cell">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <div className="card-body">
                    <div className="p-3">
                      <div id="mission">
                        <h4>
                          Providing high-quality software products and services
                          that meet the needs of our customers
                        </h4>
                        <ul>
                          <li>
                            Researching and understanding the needs and pain
                            points of customers, and developing software
                            solutions that address those needs.
                          </li>
                          <li>
                            Building and maintaining strong relationships with
                            customers, and being responsive to their needs and
                            feedback.
                          </li>
                          <li>
                            Ensuring that software products are reliable,
                            user-friendly, and meet the required performance
                            standards.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="p-3">
                      <div id="mission">
                        <h4>
                          Innovating and staying at the forefront of technology
                          to create new and improved software solutions
                        </h4>
                        <ul>
                          <li>
                            Researching and staying up-to-date on emerging
                            technologies and trends in the software industry.
                          </li>
                          <li>
                            Investing in research and development to create new
                            and improved software solutions.
                          </li>
                          <li>
                            Partnering with other companies or organizations to
                            access new technologies and expertise.
                          </li>
                          <li>
                            Incorporating feedback and insights from customers
                            and other stakeholders into the development process.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="p-3">
                      <div id="mission">
                        <h4>
                          Creating value for shareholders and other stakeholders
                          through profitable business operations
                        </h4>
                        <ul>
                          <li>
                            Setting clear financial goals and tracking progress
                            towards those goals.
                          </li>
                          <li>
                            Identifying and pursuing new business opportunities
                            that align with the company's mission and values.
                          </li>
                          <li>
                            Managing costs and resources effectively to maximize
                            profitability.
                          </li>
                          <li>
                            Communicating transparently and openly with
                            shareholders and other stakeholders about the
                            financial performance of the company.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="p-3">
                      <div id="mission">
                        <h4>
                          Making a positive impact on the communities in which
                          our company operates
                        </h4>
                        <ul>
                          <li>
                            Giving back to the community through charitable
                            donations or volunteer efforts.
                          </li>
                          <li>
                            Being a good corporate citizen and complying with
                            all relevant laws and regulations.
                          </li>
                          <li>
                            Working to minimize the environmental impact of the
                            company's operations.
                          </li>
                          <li>
                            Supporting diversity, equity, and inclusion within
                            the company and in the broader community.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* SocialMedia Component*/}
        <SocialMedia />

        {/* Footer Component*/}
        <Footer />
      </Aux>
    );
  }
}
export default Mission;
