import "url-search-params-polyfill";

import Aux from "../hoc/Aux_";
import Aws from "../components/Careers/Aws";
import Footer from "../components/Footer";
import React from "react";
import Sdet from "../components/Careers/Sdet";
import SocialMedia from "../components/SocialMedia";

class Careers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  
  render() {
    const bkg1 = {
      backgroundImage: "url(images/wave-shape/wave1.png)",
    };
    const bkg2 = {
      backgroundImage: "url(images/wave-shape/wave2.png)",
    };
    const bkg3 = {
      backgroundImage: "url(images/wave-shape/wave3.png)",
    };

    return (
      <Aux>
        <div className="account-home-btn d-sm-block">
          <a href="/" className="text-white">
            <i className="mdi mdi-home h1" />
          </a>
        </div>
        <section
          className="section bg-home home-half"
          id="home"
          data-image-src="images/bg-home.jpg"
        >
          <div className="bg-overlay" />
          <div className="display-table">
            <div className="display-table-cell">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 offset-lg-2 text-white text-center">
                    <h1 className="home-title">Join Altus Techs Today...</h1>
                    <p className="padding-t-15 home-desc">
                      We are currently seeking talented software engineers to
                      join our team. Our engineers work on a wide range of
                      projects, from building custom applications to improving
                      existing systems. We value creativity, collaboration, and
                      a commitment to continuous learning. Job openings are listed below.
                    </p>
                    <p className="play-shadow margin-t-30 margin-l-r-auto"> </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="wave-effect wave-anim">
            <div className="waves-shape shape-one">
              <div className="wave wave-one" style={bkg1} />
            </div>
            <div className="waves-shape shape-two">
              <div className="wave wave-two" style={bkg2} />
            </div>
            <div className="waves-shape shape-three">
              <div className="wave wave-three" style={bkg3} />
            </div>
          </div>
        </section>
        <div className="display-table">
          <div className="display-table-cell">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <div className="card account-card">
                    <div className="card-body">
                      <div className="p-3">
                        <div>
                          <Aws />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card account-card">
                    <div className="card-body">
                      <div className="p-3">
                        <div>
                          <Sdet />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* SocialMedia Component*/}
        <SocialMedia />

        {/* Footer Component*/}
        <Footer />
      </Aux>
    );
  }
}
export default Careers;
