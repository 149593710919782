import React from "react";

//import { Link } from 'react-router-dom';

class TestAutomation extends React.Component {
  render() {
    return (
      <section className="section bg-white" id="test-automation">
        <div className="container">
          <div className="row vertical-content">
            <div className="col-lg-5">
              <div className="features-img features-right text-right">
                <img
                  src="images/services/test-automation.svg"
                  alt="macbook"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="features-box">
                <h3>Testing and Test Automation</h3>
                <p className="text-muted web-desc">
                  We are also specialized in test automation of web, mobile and
                  desktop applications to improve your development efficiency
                  and reduce cost. We are excellent in providing comprehensive
                  software testing services and more to ensure the quality,
                  stability, reliability and long-term sustainability of your
                  customized products & services.
                </p>
                {/* <Link to='' className='btn btn-custom margin-t-30 waves-effect waves-light'>
                  Learn More <i className='mdi mdi-arrow-right' />
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default TestAutomation;
