import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 margin-t-20">
              <h4>
                <a className="text-white" href="#/">
                  Home
                </a>
              </h4>
            </div>
            <div className="col-lg-2 margin-t-20">
              <h4>
                <a className="text-white" href="#/about-us">
                  About Us
                </a>
              </h4>
            </div>
            <div className="col-lg-2 margin-t-20">
              <h4>
                <a className="text-white" href="#/mission">
                  Mission
                </a>
              </h4>
            </div>
            <div className="col-lg-2 margin-t-20">
              <h4>
                <a className="text-white" href="#/careers">
                  Careers
                </a>
              </h4>
            </div>
            <div className="col-lg-4" style={{marginTop: `15px`}}>
              <div className="float-right pull-none ">
                <p className="copy-rights text-muted">
                  {new Date().getFullYear()} © Altus Techs
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
export default Footer;
