import React from "react";

//import { Link } from 'react-router-dom';

class ConsultingService extends React.Component {
  render() {
    return (
      <section className="section bg-gray" id="consulting-service">
        <div className="container">
          <div className="row vertical-content">
            <div className="col-lg-5">
              <div className="features-box">
                <h3>IT Consulting Services</h3>
                <p className="text-muted web-desc">
                  We provide businesses with outstanding resources to identify
                  and solve IT problems, assess and improve IT infrastructure,
                  implement new technology, provide expert advice and improvise
                  your operational performance. We work with you on quantifying
                  benefits, impact to clients, assessing risk as well as help
                  you develop a cost/time model for your IT investment.
                </p>
                {/* <Link to='' className='btn btn-custom margin-t-30 waves-effect waves-light'>
                  Learn More <i className='mdi mdi-arrow-right' />
                </Link> */}
              </div>
            </div>
            <div className="col-lg-7">
              <div className="features-img features-right text-right">
                <img
                  src="images/services/consulting.png"
                  alt="macbook"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default ConsultingService;
