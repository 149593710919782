import React from 'react';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';

const officeLocation = { lat: 32.934658, lng: -96.742909 };
const LocationMap = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap defaultZoom={13} defaultCenter={officeLocation}>
      {props.isMarkerShown && <Marker position={officeLocation} />}
    </GoogleMap>
  ))
);

export default LocationMap;
