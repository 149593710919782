import React from "react";

//import { Link } from 'react-router-dom';

class ConsultingService extends React.Component {
  render() {
    return (
      <section className="section bg-white" id="mobile-development">
        <div className="container">
          <div className="row vertical-content">
            <div className="col-lg-5">
              <div className="features-img features-right text-right">
                <img
                  src="images/services/mobile-development.png"
                  alt="macbook"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="features-box">
                <h3>Mobile App Development</h3>
                <p className="text-muted web-desc">
                  We create quality mobile applications by bridging the user
                  specification and latest trending technologies used in
                  industry. We help your business to improve efficiency, enhance
                  communication, increase customer engagement, improve customer
                  service, increase sales, improve data collection and analysis
                  and gain edge over competitors.
                </p>
                {/* <Link to='' className='btn btn-custom margin-t-30 waves-effect waves-light'>
                  Learn More <i className='mdi mdi-arrow-right' />
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default ConsultingService;
