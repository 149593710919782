import { HashRouter, Route, Switch } from "react-router-dom";

import AboutUs from "./pages/AboutUs";
import Careers from "./pages/Careers";
import Home from "./pages/Home";
import Mission from "./pages/Mission";
import React from "react";
import ReactDOM from "react-dom";

//import Login from './Login';
//import PasswordForget from './PasswordForget';
//import SignUp from './SignUp';

class Root extends React.Component {
  render() {
    return (
      <HashRouter basename={"/"}>
        <Switch>
          <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
          <Route
            path={`${process.env.PUBLIC_URL}/about-us`}
            component={AboutUs}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/mission`}
            component={Mission}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/careers`}
            component={Careers}
          />
          {/* <Route path={`${process.env.PUBLIC_URL}/password-forget`} component={PasswordForget} />
          <Route path={`${process.env.PUBLIC_URL}/sign-up`} component={SignUp} /> */}
        </Switch>
      </HashRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
