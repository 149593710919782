import React from "react";

class Aws extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div id="sdet">
        <div className="text-center mt-3">
          <h3 className="font-weight-bold">
            Software Development Engineer at Test
          </h3>
          <p className="text-muted">Actively Hiring ...</p>
        </div>
        <div className="form-group">
          <label>Responsibilities</label>
          <ul className="text-muted">
            <li>
              Creates and owns review and execution of project test plans, test
              cases, automation and all QA-related collateral and documentation
              for a feature or product area.
            </li>
            <li>
              Creates and leverages automation during all phases of the test
              process using Selenium and Java.
            </li>
            <li>Reports and analyzes test results and discovered defects.</li>
            <li>Maintains automated testing tools as needed.</li>
            <li>Provides accurate project estimates for assigned tasks.</li>
            <li>Mentors junior team members.</li>
            <li>
              Creates and leverages automation during all phases of the test
              process using Selenium and Java.
            </li>
          </ul>
        </div>
        <div className="form-group">
          <label>Requirements & Skills</label>
          <ul className="text-muted">
            <li>
              5+ years’ recent hands-on experience with designing, coding, and
              executing automated test solutions using Selenium, Java and
              TestNG. Comparable experience with C#, Ranorex or equivalent may
              be considered.
            </li>
            <li>
              5+ years’ progressive, hands-on software quality assurance
              experience, with a sound understanding of quality processes and
              methodologies.
            </li>
            <li>
              Solid proficiency in web technologies, including: HTML, CSS,
              client scripting, DOM interrogation and manipulation.
            </li>
            <li>
              Must have a strong understanding of object-oriented programming
              and demonstrate solid technical design skills.
            </li>
            <li>
              Ability to mentor other automation Analysts and members of the QA
              team in automated test execution and troubleshooting.
            </li>
            <li>
              Excellent skills in all of the following areas: analysis and
              problem solving, good development practices, troubleshooting,
              debugging and code maintenance.
            </li>
            <li>
              Database knowledge in a major RDBMS system such as DB2, MS SQL
              Server or Oracle.
            </li>
            <li>Experience with automated testing of API’s.</li>
            <li>Strong verbal and written communication skills.</li>
          </ul>
        </div>
        <div className="form-group">
          <label>Apply:</label>
          <a href="mailto: jobs@altustechs.com"> jobs@altustechs.com</a>
        </div>
      </div>
    );
  }
}

Aws.propTypes = {};

export default Aws;
