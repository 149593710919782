import "url-search-params-polyfill";

import Aux from "../hoc/Aux_";
import Footer from "../components/Footer";
import React from "react";
import SocialMedia from "../components/SocialMedia";

class AboutUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  
  render() {
    const bkg1 = {
      backgroundImage: "url(images/wave-shape/wave1.png)",
    };
    const bkg2 = {
      backgroundImage: "url(images/wave-shape/wave2.png)",
    };
    const bkg3 = {
      backgroundImage: "url(images/wave-shape/wave3.png)",
    };

    return (
      <Aux>
        <div className="account-home-btn d-sm-block">
          <a href="/" className="text-white">
            <i className="mdi mdi-home h1" />
          </a>
        </div>
        <section
          className="section bg-home home-half"
          id="home"
          data-image-src="images/bg-home.jpg"
        >
          <div className="bg-overlay" />
          <div className="display-table">
            <div className="display-table-cell">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 offset-lg-2 text-white text-center">
                    <h1 className="home-title">About Altus Techs</h1>
                    <p className="padding-t-15 home-desc">
                      Altus Techs is a domestic IT and engineering solution
                      provider whose business focuses on enterprise application
                      development and consulting. We offer an optimal blend of
                      business processes and IT solutions in varied domains to
                      enable our customers to meet their corporate goals.
                    </p>
                    <p className="play-shadow margin-t-30 margin-l-r-auto"> </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="wave-effect wave-anim">
            <div className="waves-shape shape-one">
              <div className="wave wave-one" style={bkg1} />
            </div>
            <div className="waves-shape shape-two">
              <div className="wave wave-two" style={bkg2} />
            </div>
            <div className="waves-shape shape-three">
              <div className="wave wave-three" style={bkg3} />
            </div>
          </div>
        </section>
        <div className="display-table">
          <div className="display-table-cell">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <div className="card-body">
                    <div className="p-3">
                      <div id="mission">
                        <h4>Application Development</h4>
                        <p>
                          With more than 20 years of experience in architecting,
                          designing and developing enterprise applications, we
                          provide one-stop source for custom software
                          applications designed to automate your business
                          processes, improve efficiency, support
                          decision-making, and delight your users with fast
                          performance and stunning user experiences.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="p-3">
                      <div id="mission">
                        <h4>Mobile App Development</h4>
                        <p>
                          We create quality mobile applications by bridging the
                          user specification and latest trending technologies
                          used in industry. We help your business to improve
                          efficiency, enhance communication, increase customer
                          engagement, improve customer service, increase sales,
                          improve data collection and analysis and gain edge
                          over competitors.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="p-3">
                      <div id="mission">
                        <h4>IT Consulting Services</h4>
                        <p>
                          We provide businesses with outstanding resources to
                          identify and solve IT problems, assess and improve IT
                          infrastructure, implement new technology, provide
                          expert advice and improvise your operational
                          performance. We work with you on quantifying benefits,
                          impact to clients, assessing risk as well as help you
                          develop a cost/time model for your IT investment.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="p-3">
                      <div id="mission">
                        <h4>Testing and Test Automation</h4>
                        <p>
                          We are also specialized in test automation of web,
                          mobile and desktop applications to improve your
                          development efficiency and reduce cost. We are
                          excellent in providing comprehensive software testing
                          services and more to ensure the quality, stability,
                          reliability and long-term sustainability of your
                          customized products & services.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="p-3">
                      <div id="mission">
                        <h4>Web Design</h4>
                        <p>
                          Our proficiency lies with website design, website
                          graphic design, corporate identity and logo design,
                          brochure design, banner design, E-catalog and
                          e-publishing services and more. Our web design service
                          can help businesses establish and maintain a strong
                          online presence, which can be important for attracting
                          and engaging customers and supporting business goals.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* SocialMedia Component*/}
        <SocialMedia />

        {/* Footer Component*/}
        <Footer />
      </Aux>
    );
  }
}
export default AboutUs;
