import AppDevelopment from '../components/Services/AppDevelopment';
import Aux from '../hoc/Aux_';
import ConsultingService from '../components/Services/ConsultingService';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import MobileDevelopment from '../components/Services/MobileDevelopment';
import Navbar from '../components/Navbar';
import React from 'react';
import SocialMedia from '../components/SocialMedia';
import TestAutomation from '../components/Services/TestAutomation';
import WebDesign from '../components/Services/WebDesign';

//import BootCamps from '../components/Services/BootCamps';

//import Services from '../components/Services';
//import Team from '../components/Team';
//import Advertisement from '../components/Advertisement';
//import Features from '../components/Features';
//import Process from '../components/Process';
//import Training from '../components/Training';
//import BootCamps from '../components/BootCamps';
//import Testimony from '../components/Testimony';
//import { Link } from 'react-router-dom';
//import FooterLinks from './components/FooterLinks';

class Home extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  
  render() {
    const bkg1 = {
      backgroundImage: "url(images/wave-shape/wave1.png)",
    };
    const bkg2 = {
      backgroundImage: "url(images/wave-shape/wave2.png)",
    };
    const bkg3 = {
      backgroundImage: "url(images/wave-shape/wave3.png)",
    };

    return (
      <Aux>
        {/* Navbar Component*/}
        <div>
          <Navbar />
        </div>
        <section
          className="section bg-home home-half"
          id="home"
          data-image-src="images/bg-home.jpg"
        >
          <div className="bg-overlay" />
          <div className="display-table">
            <div className="display-table-cell">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 offset-lg-2 text-white text-center">
                    <h1 className="home-title">Welcome to Altus Techs</h1>
                    <p className="padding-t-15 home-desc">
                      Altus Techs is a domestic IT and engineering solution
                      provider whose business focuses on enterprise application
                      development and consulting. We offer an optimal blend of
                      business processes and IT solutions in varied domains to
                      enable our customers to meet their corporate goals.
                    </p>
                    <p className="play-shadow margin-t-30 margin-l-r-auto">
                      {" "}
                      {/* <Link to='http://vimeo.com/99025203' className='play-btn video-play-icon'> 
                        <i className='mdi mdi-play text-center' />
                      </Link>*/}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="wave-effect wave-anim">
            <div className="waves-shape shape-one">
              <div className="wave wave-one" style={bkg1} />
            </div>
            <div className="waves-shape shape-two">
              <div className="wave wave-two" style={bkg2} />
            </div>
            <div className="waves-shape shape-three">
              <div className="wave wave-three" style={bkg3} />
            </div>
          </div>
        </section>

        {/* Services Component
				<Services />
				*/}
        <AppDevelopment />
        <MobileDevelopment />
        <ConsultingService />
        <TestAutomation />
        <WebDesign />
        {/* Training Component
        <BootCamps />
        <Training />*/}

        {/* BootCamps Component
        <BootCamps />*/}

        {/* Process Component
        <Process />*/}

        {/* Advertisement 
        <Advertisement />*/}

        {/* Features Component
        <Features />*/}

        {/* Team Component
        <Team />*/}

        {/* Testimony Component
        <Testimony />*/}

        {/* Contact Component*/}
        <Contact />

        {/* SocialMedia Component*/}
        <SocialMedia />

        {/* Footer Component*/}
        <Footer />

        {/* FooterLinks Component
        <FooterLinks />*/}
      </Aux>
    );
  }
}

export default Home;
