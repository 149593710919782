import React from 'react';

//import { Link } from 'react-router-dom';

class AppDevelopment extends React.Component {
  render() {
    return (
      <section className="section bg-gray" id="app-development">
        <div className="container">
          <div className="row vertical-content">
            <div className="col-lg-5">
              <div className="features-box">
                <h3>Application Development</h3>
                <p className="text-muted web-desc">
                  With more than 20 years of experience in architecting,
                  designing and developing enterprise applications, we provide
                  one-stop source for custom software applications designed to
                  automate your business processes, improve efficiency, 
                  support decision-making, and delight your users with fast
                  performance and stunning user experiences.
                </p>
                {/* <Link to='' className='btn btn-custom margin-t-30 waves-effect waves-light'>
                  Learn More <i className='mdi mdi-arrow-right' />
                </Link> */}
              </div>
            </div>
            <div className="col-lg-7">
              <div className="features-img features-right text-right">
                <img
                  src="images/services/app-development.png"
                  alt="macbook"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default AppDevelopment;
