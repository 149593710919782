import React from "react";

class Aws extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div id="aws">
        <div className="text-center mt-3">
          <h3 className="font-weight-bold">AWS Developer</h3>
          <p className="text-muted">Actively Hiring ...</p>
        </div>
        <div className="form-group">
          <label>Responsibilities</label>
          <ul className="text-muted">
            <li>
              Understand an organization's present application infrastructure
              and make comments and/or proposals to enhance or improve it
            </li>
            <li>
              Define and document best practices and methods for application
              deployment and infrastructure upkeep
            </li>
            <li>
              Collaborate with the IT team or department on the migration of web
              apps to AWS
            </li>
            <li>Create, plan, and implement low-cost migration techniques</li>
            <li>
              Perform software analysis, testing, debugging, and updating to
              ensure that programmes are compatible with all web browsers
            </li>
            <li>
              Create a serverless application using multiple AWS services such
              as API, RDS instance, and Lambda
            </li>
          </ul>
        </div>
        <div className="form-group">
          <label>Requirements & Skills</label>
          <ul className="text-muted">
            <li>
              A bachelor's or master's degree in computer science, information
              technology, or a related field
            </li>
            <li>
              Prior experience planning, designing, developing, architecting,
              and deploying cloud applications on AWS platforms
            </li>
            <li>
              Knowledge of fundamental AWS services, applications, and best
              practices for AWS architecture
            </li>
            <li>
              Practical expertise in a variety of disciplines, such as database
              architecture, business intelligence, machine learning, advanced
              analytics, big data, etc
            </li>
            <li>Significant experience in Linux/Unix administration</li>
            <li>Knowledge of Docker and Kubernetes</li>
            <li>
              Experience working with cross-functional teams of business
              analysts, data scientists, subject matter experts, and developers
            </li>
            <li>
              The ability to recognize and fix problems in distributed systems
              fast
            </li>
            <li>Self-starter, team player, and problem solver</li>
            <li>Prior experience in large-scale system design</li>
            <li>Understanding of web services such as API, REST, and RPC</li>
            <li>
              AWS credentials, as well as any other professional certifications
            </li>
          </ul>
        </div>
        <div className="form-group">
          <label>Apply:</label>
          <a href="mailto: jobs@altustechs.com"> jobs@altustechs.com</a>
        </div>
      </div>
    );
  }
}

Aws.propTypes = {};

export default Aws;
